import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class AdminSideNav extends Component {

  render() {
    return <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div className="main-navbar">
        <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <Link className="navbar-brand w-100 mr-0" href="#" to="/" style={{ lineHeight: "25px" }}>
            <div className="d-table m-auto">
              <img id="main-logo" className="d-inline-block align-top mr-1" style={{ maxWidth: "50px" }} src="https://static.wixstatic.com/media/dbc393_7e1659bad6044278b30ff8d6d4667516~mv2_d_2963_1605_s_2.jpg/v1/fill/w_111,h_60,al_c,q_80,usm_0.66_1.00_0.01/LdotR_Logo.webp" alt="LDotR" />
            </div>
          </Link>
          <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </nav>
      </div>


      <div className="nav-wrapper">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className={window.location.pathname === '/analyst-dashboard' ? "nav-link active" : 'nav-link'} to="/analyst-dashboard">
              <span>DASHBOARD</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/users' ? "nav-link active" : 'nav-link'} to="/users">
              <span>USERS</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/companies' ? "nav-link active" : 'nav-link'} to="/companies">
              <span>COMPANY</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/brands' ? "nav-link active" : 'nav-link'} to="/brands">
              <span>BRAND</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/tmch' ? "nav-link active" : 'nav-link'} to="/tmch">
              <span>TMCH</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/data-upload' ? "nav-link active" : 'nav-link'} to="/data-upload">
              <span>DATA UPLOAD</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/edit-bulk-data' ? "nav-link active" : 'nav-link'} to="/edit-bulk-data">
              <span>EDIT BULK UPLOAD</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/enforcement' ? "nav-link active" : 'nav-link'} to="/enforcement">
              <span>ENFORCEMENT</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/bulk-enforcement' ? "nav-link active" : 'nav-link'} to="/bulk-enforcement">
              <span>BULK ENFORCEMENT</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/bulk-data-download' ? "nav-link active" : 'nav-link'} to="/bulk-data-download">
              <span>BULK DATA DOWNLOAD</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/domain-data-download' ? "nav-link active" : 'nav-link'} to="/domain-data-download">
              <span>DOMAIN DATA DOWNLOAD</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/upload-purchase' ? "nav-link active" : 'nav-link'} to="/upload-purchase">
              <span>UPLOAD PURCHASE</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={window.location.pathname === '/scrapper1' ? "nav-link active" : 'nav-link'} to="/scrapper1">
              <span>SCRAPPER-1</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className={window.location.pathname === '/scrapper2' ? "nav-link active" : 'nav-link'} to="/scrapper2">
              <span>SCRAPPER-2</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className={window.location.pathname === '/scrapper3' ? "nav-link active" : 'nav-link'} to="/scrapper3">
              <span>SCRAPPER-3</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className={window.location.pathname === '/logs' ? "nav-link active" : 'nav-link'} to="/logs">
              <span>LOG SEARCH</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  }
}


export default AdminSideNav;