import React from "react";
import Footer from "../../common/Footer.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { connect } from "react-redux";
import readXlsxFile from "read-excel-file";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import { withAlert } from "react-alert";
import { getInfrigementStatusText } from "../../../src/infrigementStatusCode";
import Select from "react-select";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import "sweetalert2/src/sweetalert2.scss";
import * as moment from "moment";

const FormData = require("form-data");

class BulkEnforcement extends React.Component {
  constructor(props) {
    super(props);
    this.saveEnforcementData = this.saveEnforcementData.bind(this);

    this.state = {
      sorting: {
        sortBy: "",
        sortIconClass: "sort-icon-nutral",
        sortOrder: "",
      },
      checkedInfrigements: [],
      registrarFilter: "",
      ipAddressFilter: "",
      infrigementStatusFilter: "",
      hostingFilter: "",
      actionTakenFilter: "",
      infrigementStatus: null,
      enforcementData: [],
      currentInfrigementId: null,
      showModal: false,
      showAddEnforcementModal: false,
      authorised: true,
      platform: "",
      uniqueParameter: "",
      showDataSavedAlert: false,
      showFilesUploadedAlert: false,
      uploadedFiles: {},
      excelData: [],
      companies: [],
      brands: [],
      reportingDate: "",
      company: "",
      brand: "",
      files: [],
      errorMessage: null,
      filters: {},
      availablePlatforms: null,
      domainKeys: ["image", "domain", "tld", "priority", "status", "registrar"],
      websiteKeys: [
        "image",
        "domain",
        "url",
        "reportingDate",
        "priority",
        "status",
      ],
      socialMediaKeys: [
        "image",
        "platform",
        "heading",
        "link",
        "reportingDate",
        "priority",
        "status",
      ],
      ecommerceKeys: [
        "image",
        "site",
        "reportingDate",
        "url",
        "title",
        "status",
        "priority",
        "price",
        "reason",
        "sellerRating",
      ],
      appStoreKeys: [
        "image",
        "appName",
        "appStore",
        "reportingDate",
        "url",
        "developerName",
        "latestPublishDate",
        "status",
        "priority",
        "price",
      ],
      selectedKey: "",
      availableReportingDate: [],
    };
  }

  handleChange = (selectedOption) => {
    const selectedBrand = this.state.brands.find(
      (brand) => brand.id === selectedOption.value
    );

    this.setState({
      brand: selectedOption.value,
      brandId: selectedBrand ? selectedBrand.id : null,
    });
  };

  changeInputEvent() {
    const input = document.getElementById("input");

    input.addEventListener("change", () => {
      if (!this.state.platform) {
        alert("No excel file found.");
        return;
      }

      if (input.files.length < 1) {
        alert("No excel file found.");
        return;
      }

      const file = input.files[0];

      if (
        file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        input.value = null;
        alert("Please upload excel file");
        return;
      }

      readXlsxFile(file).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
        let formData = [];

        for (let row in rows) {
          let item = rows[row];

          if (row > 0) {
            let statusCode = String(item[1]);

            if (!item[0] || !getInfrigementStatusText(statusCode)) {
              continue;
            }

            let fromItem = {};
            fromItem[this.state.uniqueParameter] = String(item[0]);
            fromItem["status"] = statusCode;
            fromItem["registrar"] = String(item[2]);
            fromItem["ipAddress"] = String(item[3]);
            fromItem["hosting"] = String(item[4]);
            fromItem["actionTaken"] = String(item[5]);
            fromItem["uniqueParameter"] = this.state.uniqueParameter;

            if (
              this.state.platform !== "domain" &&
              this.state.platform !== "website"
            ) {
              fromItem["registrar"] = null;
              fromItem["ipAddress"] = null;
              fromItem["hosting"] = null;
              fromItem["actionTaken"] = String(item[2]);
            }

            formData.push(fromItem);
          }
        }

        this.setState(() => ({ excelData: formData }));
      });
    });
  }

  async saveEnforcementData() {
    let platform = this.state.platform;

    let url = `/enforcement/bulk-enforcement`;

    let params = {
      company: this.state.company,
      brand: this.state.brand,
      platform,
      data: this.state.excelData,
    };

    await window.axios
      .post(url, params)
      .then((response) => {
        this.props.alert.show(response.data.message);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data?.errors);
          this.setState(() => {
            return {
              errorMessage: error.response.data?.errors,
            };
          });
        }
      });
  }

  async changeInputBox(e, column) {
    let state = {};
    state[column] = e.target.value;
    await this.setState(() => {
      return state;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();
    }
  }

  componentDidMount() {
    this.changeInputEvent();
    this.changePlatformSelectBox("domain");
    this.getCompanies();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800, //JiraId :- LDOTR-3
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  changeBrandSelectBox(e) {
    this.setState(() => ({
      brand: e.target.value,
    }));
  }

  changeCompanySelectBox(e) {
    this.setState(() => ({
      company: e.target.value,
      brand: "",
      brands: [],
    }));
  }

  changePlatformSelectBox(key) {
    let selectedKeyValue = "domainKeys";
    let uniqueParameter = "domain";

    if (key === "website") {
      selectedKeyValue = "websiteKeys";
      uniqueParameter = "url";
    }
    if (key === "social-media") {
      selectedKeyValue = "socialMediaKeys";
      uniqueParameter = "link";
    }
    if (key === "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
      uniqueParameter = "url";
    }
    if (key === "app-store") {
      selectedKeyValue = "appStoreKeys";
      uniqueParameter = "url";
    }

    this.setState(() => ({
      platform: key,
      uniqueParameter: uniqueParameter,
      excelData: [],
      selectedKey: selectedKeyValue,
      registrarFilter: "",
      ipAddressFilter: "",
      hostingFilter: "",
      actionTakenFilter: "",
      availableReportingDate: [],
      filters: {},
      checkedInfrigements: [],
    }));
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === 'Admin' ? <AdminSideNav /> : <AnalystSideNav />}

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Enforcement
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Company
                            </span>
                            <select
                              onChange={(e) => this.changeCompanySelectBox(e)}
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {this.state.companies.map(
                                (company, companyIndex) => (
                                  <option key={companyIndex} value={company.id}>
                                    {company.name}
                                  </option>
                                )
                              )}
                            </select>
                            {this.state.errorMessage?.company && (
                              <span className="text-danger">
                                {this.state.errorMessage?.company}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Brand
                            </span>
                            {/* <select onChange={(e) => this.changeBrandSelectBox(e)} className="form-control">
                                                <option value="">Select from dropdown</option>
                                                {
                                                    this.state.brands.map((brand, brandKey) => 
                                                        <option key={brandKey} value={brand.id}>{brand.brandName}</option>
                                                    )
                                                }
                                            </select> */}
                            <Select
                              options={options}
                              onChange={this.handleChange}
                            />
                            {this.state.errorMessage?.brand && (
                              <span className="text-danger">
                                {this.state.errorMessage?.brand}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              value={this.state.platform}
                              onChange={(e) =>
                                this.changePlatformSelectBox(e.target.value)
                              }
                              className="form-control"
                            >
                              <option value="">Select from dropdown</option>
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.domain) ||
                                !this.state.availablePlatforms) && (
                                  <option value="domain">Domain</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.website) ||
                                !this.state.availablePlatforms) && (
                                  <option value="website">Website</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.socialMedia) ||
                                !this.state.availablePlatforms) && (
                                  <option value="social-media">
                                    Social Media
                                  </option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.eCommerce) ||
                                !this.state.availablePlatforms) && (
                                  <option value="e-commerce">E-commerce</option>
                                )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.appStore) ||
                                !this.state.availablePlatforms) && (
                                  <option value="app-store">App Store</option>
                                )}
                            </select>
                            {this.state.errorMessage?.platform && (
                              <span className="text-danger">
                                {this.state.errorMessage?.platform}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Upload Xls file *
                            </span>
                            <input id="input" name="file" type="file" />
                            {this.state.errorMessage?.data && (
                              <span className="text-danger">
                                {this.state.errorMessage?.data}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            {/* <span className="text-muted d-block mb-2">#</span> */}
                            <button
                              type="button"
                              onClick={(e) => this.saveEnforcementData(e)}
                              className="btn btn-success"
                            >
                              Upload Enforcement Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* data saved sweet alert */}
          <SweetAlert
            show={this.state.showDataSavedAlert}
            title="Success"
            text="Data Saved Successfully !"
            onConfirm={() => this.setState({ showDataSavedAlert: false })}
          />
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(BulkEnforcement)))
);
