import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dashboard from './pages/Dashboard.js'
import Login from './pages/Login.js'
import MyNotifications from './pages/MyNotifications.js'
import AnalystDashboard from './pages/analyst/Dashboard/AnalystDashboard'
import ListCompany from './pages/analyst/ListCompany'
import ListBrand from './pages/analyst/ListBrand'
import ListTmch from './pages/analyst/ListTmch.js'
import DomainDashboard from './pages/DomainDashboard'
import WebsiteDashboard from './pages/client/WebsiteDashboard'
import SocialMediaDashboard from './pages/client/SocialMediaDashboard'
import EcommerceDashboard from './pages/client/EcommerceDashboard'
import AppStoreDashboard from './pages/client/AppStoreDashboard'
import TrapPurchaseDashboard from './pages/client/TrapPurchaseDashboard.js'
import TmchDashboard from './pages/client/TmchDashboard'
import CreateDomainRecord from './pages/analyst/CreateDomainRecord'
import DataUpload from './pages/analyst/DataUpload'
import EditBulkData from './pages/analyst/EditBulkData'
import Enforcement from './pages/analyst/Enforcement'
import BulkEnforcement from './pages/analyst/BulkEnforcement'
import ClientDashboard from './pages/ClientDashboard'
import UserComponent from './pages/analyst/UserComponent'
import ListDataUpdateLog from './pages/analyst/ListDataUpdateLog.js'
import DomainSearchPage from './pages/analyst/DomainSearchPage.js'
import SearchedDomainRecordsPage from './pages/analyst/SearchedDomainRecordsPage'
import StagingDataContainer from './pages/analyst/StagingData/StagingDataContainer'
import ReviewDataContainerComponent from './pages/analyst/ReviewData/ReviewDataContainerComponent'
import BulkDataDownload from './pages/analyst/BulkDataDownload';
import DomainDataDownload from './pages/analyst/DomainDataDownload.js';
import ResetPassword from './pages/ResetPassword.js' //Component for password updation
import './App.css';
import io from 'socket.io-client'
import Notifications from 'react-notify-toast';
import UploadPurchase from './pages/analyst/UploadPurchase.js'
import Scrapper1 from './pages/analyst/Scrapper1.js'
import Scrapper2 from './pages/analyst/Scrapper2.js'
import Scrapper3 from './pages/analyst/Scrapper3.js'
import LogSearch from "./pages/analyst/LogSearch.js"
window.axios = require("axios");
window.axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
window.axios.interceptors.request.use(config => {
  // Add a request interceptor globally
  return config;
}, error => {
  return Promise.reject(error);
});


window.socket = io.connect(process.env.REACT_APP_API_BASE_URL, {
  // WARNING: in that case, there is no fallback to long-polling
  transports: ["websocket"] // or [ "websocket", "polling" ] (the order matters)
})

function App() {
  const token = useSelector(store => store.login?.token);
  window.axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "true",
    "Authorization": `Bearer ${token}`
  };

  return (
    <Router>
      <div className="container-fluid">
        <Notifications />
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/analyst-dashboard' component={AnalystDashboard} />
          <Route exact path='/client-dashboard' component={ClientDashboard} />
          <Route exact path='/companies' component={ListCompany} />
          <Route exact path='/tmch' component={ListTmch} />
          <Route exact path='/brands' component={ListBrand} />
          <Route exact path='/domain-dashboard' component={DomainDashboard} />
          <Route exact path='/website-dashboard' component={WebsiteDashboard} />
          <Route exact path='/social-media-dashboard' component={SocialMediaDashboard} />
          <Route exact path='/ecommerce-dashboard' component={EcommerceDashboard} />
          <Route exact path='/app-store-dashboard' component={AppStoreDashboard} />
          <Route exact path='/trap-purchase-dashboard' component={TrapPurchaseDashboard} />
          <Route exact path='/tmch-dashboard' component={TmchDashboard} />
          <Route exact path='/create-domain-record' component={CreateDomainRecord} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/data-upload' component={DataUpload} />
          <Route exact path='/edit-bulk-data' component={EditBulkData} />
          <Route exact path='/upload-purchase' component={UploadPurchase} />
          <Route exact path='/enforcement' component={Enforcement} />
          <Route exact path='/bulk-enforcement' component={BulkEnforcement} />
          <Route exact path='/users' component={UserComponent} />
          <Route exact path='/bulk-data-download' component={BulkDataDownload} />
          <Route exact path='/data-update-log' component={ListDataUpdateLog} />
          <Route exact path='/mynotifications' component={MyNotifications} />
          <Route exact path='/domain-search' component={DomainSearchPage} />
          <Route exact path='/domain-data-download' component={DomainDataDownload} />
          <Route exact path='/searched-domains' component={SearchedDomainRecordsPage} />
          <Route exact path='/staging' component={StagingDataContainer} />
          <Route exact path="/review" component={ReviewDataContainerComponent} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/scrapper1" component={Scrapper1} />
          <Route exact path="/scrapper2" component={Scrapper2} />
          <Route exact path="/scrapper3" component={Scrapper3} />
          <Route exact path="/logs" component={LogSearch} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;